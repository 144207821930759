import { Autocomplete, TextField } from "@mui/material";
import ListWrapper from "app/components/ListWrapper";
import useGetCandidateApplication from "app/hooks/candidate/useGetCandidateApplication";
import useAuth from "app/hooks/useAuth";
import { useDebounce } from "app/hooks/useDebounce";
import { StatusOptions } from "app/utils/global_constants";
import { useState } from "react";
import CandidateApplication from "../applied_candidate/shared/CandidateApplication";
function YourApplications(props) {
  const [searchTerm, setSearchTerm] = useState();
  const debounceSearch = useDebounce(searchTerm);
  const [status, setStatus] = useState([]);
  const { user } = useAuth();

  const { data: appliedCandidates, isFetched: isApplicationsFetched } = useGetCandidateApplication({
    id: user.id,
    debounceSearch,
    status,
    enabled: true
  });

  const handleStatusChange = (event, value) => {
    setStatus(value);
  };

  return (
    <ListWrapper
      editOpen={false}
      alertOpen={false}
      searchlabel={"Search By Job Title"}
      onChangeSearch={(value) => {
        setSearchTerm(value);
      }}
    >
      {isApplicationsFetched && (
        <Autocomplete
          multiple
          id="tags-outlined"
          options={StatusOptions}
          getOptionLabel={(option) => option.label}
          filterSelectedOptions
          onChange={handleStatusChange}
          size="small"
          value={status}
          sx={{ maxWidth: 350, mb: 2 }}
          defaultValue={status}
          renderInput={(params) => (
            <TextField {...params} label="Filter By Status" placeholder="Status" />
          )}
        />
      )}
      {isApplicationsFetched && <CandidateApplication applications={appliedCandidates} />}
    </ListWrapper>
  );
}

export default YourApplications;
