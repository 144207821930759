import React, { useState } from "react";
import PostJobForm from "./shared/PostJobForm";
import { usePostJob } from "app/hooks/job/usePostJob";
import { Fragment } from "react";
import ResponseSnackbar from "app/components/ResponseSnackbar";
import { useNavigate } from "react-router-dom";
import { useGetSkills } from "app/hooks/skill/useGetSkills";
import useAuth from "app/hooks/useAuth";
function PostJob() {
  const [alertState, setAlertState] = useState({
    error: false,
    success: false
  });
  const navigate = useNavigate();
  const { user } = useAuth();

  function closeAlert() {
    if (alertState.error) {
      setAlertState((prevState) => ({ ...prevState, error: false }));
    } else if (alertState.success) {
      setAlertState((prevState) => ({ ...prevState, success: false }));
      if (user.role === "SA") {
        navigate("/jobs/listings/sa");
      } else {
        navigate("/jobs/listings/company");
      }
    }
  }

  const onPostJobSuccess = () => setAlertState((prevState) => ({ ...prevState, success: true }));
  const onPostJobFailed = () => setAlertState((prevState) => ({ ...prevState, error: true }));

  const {
    mutate: addJobPosting,
    isError,
    isSuccess,
    isPending: isPostingJob
  } = usePostJob(onPostJobSuccess, onPostJobFailed);

  // Get skill list
  const { data: skills } = useGetSkills({ keys: [] });

  return (
    <Fragment>
      {isError && (
        <ResponseSnackbar
          message={"Oops! Something went wrong. Please try again later"}
          open={alertState.error}
          hideDuration={4000}
          onClose={closeAlert}
          variant={"filled"}
          severity={"error"}
        />
      )}
      {isSuccess && (
        <ResponseSnackbar
          message={"Job Posted Successfully!"}
          open={alertState.success}
          hideDuration={4000}
          onClose={closeAlert}
          variant={"filled"}
          severity={"success"}
        />
      )}
      <PostJobForm mutationFn={addJobPosting} skills={skills} loadingState={isPostingJob} />
    </Fragment>
  );
}

export default PostJob;
