import { isValid } from "date-fns";
import dayjs from "dayjs";
import isAbsoluteUrl from "is-absolute-url";

export const topBarHeight = 64;
export const sideNavWidth = 260;
export const navbarHeight = 60;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;

export const JobTableHeadersForHR = [
  "Company",
  "Title",
  "Posted On",
  "Type",
  "Experience",
  "City",
  "Edit",
  "Delete"
];

export const JobTableHeadersForCandidate = [
  "Company",
  "Title",
  "Posted On",
  "Type",
  "Experience",
  "City",
  "Apply"
];

export const JobTableHeadersForCompany = [
  "Title",
  "Posted On",
  "Type",
  "Experience",
  "City",
  "Edit",
  "Delete"
];

export const DeletedJobHeaders = ["Posted On", "Title", "Company", "Type", "Restore"];
export const CompanyTableHeaders = {
  Company: "jp_company_name",
  State: "jp_state",
  Country: "jp_country",
  Industry: "jp_industry",
  Email: "jp_email"
};
export const CandidateTableHeaders = {
  Name: "jp_name",
  "Contact No": "jp_contactno",
  Email: "jp_email",
  IsActive: "jp_active",
  State: "jp_state",
  Edit: "Edit",
  Delete: "Delete"
};
export const StatusOptions = [
  { label: "Pending", value: "pending" },
  { label: "Shortlisted", value: "shortlisted" },
  { label: "Accepted", value: "accepted" },
  { label: "Rejected", value: "rejected" }
];
export function getTodaysDate() {
  const date = new Date();
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // January is 0!
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export const isValidURL = (url) => {
  if (url) {
    const isValid = isAbsoluteUrl(`https://${url}`);
    return isValid || "Please enter valid url";
  }
  return true;
};

export const NameRegex = /^[A-Za-z]+(?:[ '-][A-Za-z]+)*\s*$/;
export const NumberRegex = /^\d+$/;
export const CityRegex = /^[A-Za-z0-9]+(?:[ '-][A-Za-z0-9]+)*\s*$/;
export const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const PhoneRegex =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
export const JobTitleRegex = /^(?![\d\W_]+$)(?!.*[^\w\s.#])[\w\s.#-]+$/;
export const StateRegex = /^([a-zA-Z]+(-[a-zA-Z]+)?)(?:,\s*[a-zA-Z]+(-[a-zA-Z]+)?)*$/;
export const CINRegex = /^[A-Z0-9]{21}$/;

export function formatExp(value) {
  if (isFirstDecimalZero(value)) {
    return Math.floor(value); // This will return the value as an integer
  } else {
    return value.toString(); // This will return the value as a string with decimal
  }
}

export const getFormattedExperience = (min_exp, max_exp) => {
  return min_exp && max_exp ? `${formatExp(min_exp)} - ${formatExp(max_exp)} yrs` : "Fresher";
};

export function isFirstDecimalZero(value) {
  const valueStr = value.toString();

  const parts = valueStr.split(".");

  return parts.length > 1 && parts[1] === "0";
}
export const validateDate = (date) => {
  if (dayjs.isDayjs(date)) {
    return "This field is required";
  }
  if (!isValid(date)) {
    return "Invalid date";
  }
  return true;
};

export const filterAlphabets = (event) => {
  const value = event.target.value;
  event.target.value = value.replace(/[^0-9]/g, "");
};

export const ProfileAvatarStyles = {
  bgcolor: "#222a45"
};

export const LinkStyle = { color: "#1E90FF", textDecoration: "underline" };
