import useAuth from "app/hooks/useAuth";
import { useMemo } from "react";
import { Delete, Edit, Send, Visibility } from "@mui/icons-material";
export const useGetTableActions = (handleOpenDialog) => {
  const { user } = useAuth();
  return useMemo(() => {
    if (user.role === "SA" || user.role === "company") {
      return [
        {
          icon: <Edit style={{ color: "#01579b" }} />,
          action: handleOpenDialog,
          id: "editOpen"
        },

        {
          icon: <Delete style={{ color: "#ff1744" }} />,
          action: handleOpenDialog,
          id: "deleteOpen"
        }
      ];
    }

    if (user.role === "candidate") {
      return [
        {
          icon: <Send />,
          action: handleOpenDialog,
          text: "Apply",
          id: "applyOpen",
          variant: "contained"
        }
      ];
    }
  }, [user]);
};
