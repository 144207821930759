import { Verified } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Avatar, Box, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useGetCompanyLogo from "app/hooks/company/useGetCompanyLogo";
import { formatExp } from "app/utils/global_constants";
import { convertISODateTimeToDate, convertToBase64 } from "app/utils/utils";
import { useState } from "react";
import JobBenefitsRequired from "./Job Detail Dialog/JobBenefitsRequired";
import JobExp from "./Job Detail Dialog/JobExp";
import JobLocation from "./Job Detail Dialog/JobLocation";
import JobQualification from "./Job Detail Dialog/JobQualification";
import JobSalaryRange from "./Job Detail Dialog/JobSalaryRange";
import JobSkillsRequired from "./Job Detail Dialog/JobSkillsRequired";
import JobType from "./Job Detail Dialog/JobType";
import DOMPurify from "dompurify";
import JobLocationType from "./Job Detail Dialog/JobLocationType";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

export const Pebble = styled(Box)(({ theme }) => ({
  padding: "5px 12px 8px 12px",
  borderRadius: "25px",
  fontSize: ".9em",
  fontWeight: 500
}));

export const PebbleText = styled(Typography)(({ theme }) => ({
  fontSize: "0.8rem",
  whiteSpace: "nowrap",
  textAlign: "center"
}));

const DateText = styled(Typography)(() => ({
  fontWeight: 500,
  color: "darkslategray"
}));

function getUniqueBenefits(benefits) {
  const uniqueBenefits = new Set(benefits.map((benefit) => benefit.jp_benefit));
  return [...uniqueBenefits];
}

function JobDetailDialog({ open, handleClose, jobPosting }) {
  const [showHiddenBenefits, setShowHiddenBenefits] = useState(false);
  const [showHiddenSkills, setShowHiddenSkills] = useState(false);

  const JobBenefits = getUniqueBenefits(jobPosting.Benefits).slice(0, 3);
  const JobSkills = jobPosting.jp_required_skills?.split("| ");
  const HiddenSkills = jobPosting.jp_required_skills?.split("| ").slice(3);
  const HiddenBenefits = getUniqueBenefits(jobPosting.Benefits).slice(3);
  const sanitizedDescription = DOMPurify.sanitize(jobPosting.jp_description);

  const cities = jobPosting.jp_city ? jobPosting.jp_city.split("| ") : [];
  const exp =
    jobPosting.jp_min_exp && jobPosting.jp_max_exp
      ? `${formatExp(jobPosting.jp_min_exp)} - ${formatExp(jobPosting.jp_max_exp)} yrs`
      : "Fresher";
  const { data: logo } = useGetCompanyLogo({ companyId: jobPosting.company.jp_id });

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={"lg"}
      fullWidth
    >
      {/** Dialog Heading */}
      <Box sx={{ m: 0, p: 2 }} display={"flex"} gap={2}>
        <Avatar
          src={`data:image/jpeg;base64,${convertToBase64(logo?.jp_logo?.data)}`}
          children={`${jobPosting.company.jp_company_name.charAt(0)}`}
          sx={{ bgcolor: "#222a45", width: "80px", height: "80px" }}
        />
        {/** Job Title and Company Name*/}
        <Box>
          <Typography variant="h4">{jobPosting.jp_title}</Typography>
          <Box display={"flex"} gap={2} alignItems={"center"}>
            <Typography variant="subtitle1" fontSize={"1.2em"} color={"darkgray"}>
              {jobPosting.company.jp_company_name}
            </Typography>
            {jobPosting.company.jp_is_verified ? <Verified sx={{ color: "green" }} /> : ""}
          </Box>
          {/** Start Date - End Date*/}
          <Box display="flex" gap={2} alignItems={"center"}>
            <DateText>Posted On: {convertISODateTimeToDate(jobPosting.jp_start_date)}</DateText>
            <Box sx={{ width: "10px", height: "2px", background: "black" }}></Box>
            <DateText>Apply By: {convertISODateTimeToDate(jobPosting.jp_end_date)}</DateText>
          </Box>
        </Box>
      </Box>

      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{ p: 0, m: 0 }}>
        <Grid container spacing={2}>
          {/* Description */}
          <Grid md={9} item>
            <Box
              component={"div"}
              sx={{ height: "300px", overflow: "auto", p: 2 }}
              dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
            />
          </Grid>
          {/* Quick Info */}
          <Grid
            item
            xs={12}
            md={3}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
            sx={{ backgroundColor: "#f5f5f5" }}
          >
            <Box></Box>
            <JobLocation cities={cities} />
            <JobLocationType locationType={jobPosting?.jp_location} />
            <JobSalaryRange
              minSalaryRange={jobPosting?.jp_minimum_salary}
              maxSalaryRange={jobPosting?.jp_maximum_salary}
            />
            <JobExp exp={exp} />
            <JobQualification qualification={jobPosting.jp_qualification} />
            <JobType type={jobPosting.jp_type} />
            <div></div>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems={"center"}
          py={1}
          px={2}
          sx={{ boxShadow: "0 -4px 10px rgba(0, 0, 0, 0.2)" }}
        >
          <Grid item md={6}>
            {/* Skills */}
            <JobSkillsRequired
              showHiddenSkills={showHiddenSkills}
              hiddenSkills={HiddenSkills}
              jobSkills={JobSkills}
              setShowHiddenSkills={setShowHiddenSkills}
            />
          </Grid>

          {/* Benefits */}
          <Grid item md={6}>
            <JobBenefitsRequired
              allBenefits={jobPosting?.Benefits}
              showHiddenBenefits={showHiddenBenefits}
              hiddenBenefits={HiddenBenefits}
              jobBenefits={JobBenefits}
              setShowHiddenBenefits={setShowHiddenBenefits}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </BootstrapDialog>
  );
}

export default JobDetailDialog;
