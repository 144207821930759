import React from "react";

import { Controller } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";
function ControlledAutocomplete({
  key,
  name,
  label,
  values: options,
  value,
  control,
  sxValues,
  helperText,
  fullWidth,
  filterOptions,
  isOptionEqualToValue = () => {},
  onValueSelected = (value) => {},
  ...rest
}) {
  let preloadedValue = undefined;
  if (value) {
    preloadedValue = options.find((option) => {
      return option.label.toLowerCase() === value.toLowerCase();
    });
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={preloadedValue}
      rules={{ required: helperText }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <Autocomplete
          key={key}
          value={preloadedValue}
          onChange={(e, value) => {
            onChange(value);
            onValueSelected(value);
          }}
          fullWidth={fullWidth}
          options={options || []}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={isOptionEqualToValue}
          filterSelectedOptions
          filterOptions={filterOptions}
          style={{ minWidth: "250px" }}
          renderInput={(params) => (
            <TextField
              error={!!error}
              helperText={error ? error.message : null}
              {...params}
              {...rest}
              sx={sxValues}
              label={label}
            />
          )}
        ></Autocomplete>
      )}
    />
  );
}

export default ControlledAutocomplete;
