import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { Subtitle } from "../../ApplicationDetail";
const DetailItem = ({ label, detail }) => {
  return (
    <Box>
      <Subtitle label={label} />
      <Typography variant="body1" fontWeight={500}>
        {detail}
      </Typography>
    </Box>
  );
};
function CandidateInfoGrid({ candidate }) {
  const location =
    candidate.jp_city && candidate.jp_state
      ? `${candidate.jp_city}, ${candidate.jp_state}`
      : "Not specified";
  const qualification =
    candidate.jp_qualification && candidate.jp_degree_name
      ? `${candidate.jp_qualification} (${candidate.jp_degree_name})`
      : "Not specified";

  return (
    <Grid container gap={2}>
      <Grid item md={4}>
        <DetailItem label={"Name"} detail={candidate.jp_candidate_name} />
      </Grid>
      <Grid item md={4}>
        <DetailItem label={"Years Of Experience"} detail={candidate.jp_yoe || "Not specified"} />
      </Grid>
      <Grid item md={4}>
        <DetailItem label={"From"} detail={location || "Not specified"} />
      </Grid>
      <Grid item md={4}>
        <DetailItem
          label={"Current Company"}
          detail={candidate.jp_current_company || "Not specified"}
        />
      </Grid>
      <Grid item md={4}>
        <DetailItem label={"Qualification"} detail={qualification || "Not specified"} />
      </Grid>
      <Grid item md={4}>
        <DetailItem
          label={"Notice Period"}
          detail={candidate.jp_notice_period || "Not specified"}
        />
      </Grid>
      <Grid item md={4}>
        <DetailItem label={"Current CTC"} detail={candidate.jp_current_ctc || "Not specified"} />
      </Grid>
    </Grid>
  );
}

export default CandidateInfoGrid;
