import { useState, lazy, memo } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { CircularProgress, Tab, Box } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import AboutCandidate from "./AboutCandidate";
import ChangePassword from "./ChangePassword";
import { Suspense } from "react";

const LazyTab2Content = lazy(() => import("../../candidate/shared/AddCandidateForm"));
const MemoizedTab2Content = memo(LazyTab2Content);
function CandidateTabs({ updateCandidate, candidate }) {
  const { state } = useLocation();
  const { tab: paramsTab } = useParams();
  let stateTab = "1";
  if (state && state?.tab) {
    stateTab = state.tab;
  } else if (paramsTab) {
    stateTab = paramsTab;
  }

  const [tab, setTab] = useState(stateTab);

  let education = "";
  let address = "";

  if (candidate) {
    if (candidate.jp_qualification && candidate.jp_degree_name) {
      education = `${candidate?.jp_qualification} ${candidate?.jp_degree_name}`;
    } else {
      education = "Please add education information in the 'Edit' tab";
    }

    if (candidate.jp_address && candidate.jp_city && candidate.jp_state) {
      address = `${candidate?.jp_address}, ${candidate?.jp_city}, ${candidate?.jp_state}`;
    } else {
      address = "Please add address information in the 'Edit' tab";
    }
  }
  return (
    <TabContext value={tab} sx={{ width: "100%" }}>
      <TabList onChange={(event, value) => setTab(value)}>
        <Tab label="About" value={"1"} />
        <Tab label="Edit" value={"2"} />
        <Tab label="Security" value={"3"} />
      </TabList>
      <TabPanel value={"1"}>
        <AboutCandidate
          about={candidate?.jp_about_me || "Add your bio in the 'Edit' section"}
          education={education}
          address={address}
          contact={candidate.jp_contactno}
          email={candidate.jp_email}
        />
      </TabPanel>
      <TabPanel value={"2"}>
        <Suspense
          fallback={
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <CircularProgress />
            </Box>
          }
        >
          {candidate && (
            <MemoizedTab2Content
              mutationFn={updateCandidate}
              preloadedData={candidate}
              title={"Edit Your Profile"}
            />
          )}
        </Suspense>
      </TabPanel>
      <TabPanel value={"3"}>
        <ChangePassword />
      </TabPanel>
    </TabContext>
  );
}

export default CandidateTabs;
