import { useQuery } from "@tanstack/react-query";
import { getApplicants } from "app/api/application_api";
import useAuth from "../useAuth";

export const useGetApplicants = ({
  sort,
  column,
  limit,
  offset,
  searchQuery,
  post,
  status,
  id,
  isCandidateDeleted
}) => {
  const { user } = useAuth();
  return useQuery({
    queryKey: [
      "candidates",
      sort,
      column,
      limit,
      offset,
      searchQuery,
      post,
      status,
      id,
      isCandidateDeleted
    ],
    queryFn: () =>
      getApplicants(sort, column, limit, offset, searchQuery, post, status, isCandidateDeleted, id),
    enabled: user.role === "SA"
  });
};
