import { EmailSharp } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import { MatxLoading } from "app/components";
import ListWrapper from "app/components/ListWrapper";
import ResponseSnackbar from "app/components/ResponseSnackbar";
import SendMailForm from "app/components/SendMailForm";
import { useGetApplicants } from "app/hooks/application/useGetApplicants";
import { useGetApplicantsForCompany } from "app/hooks/application/useGetApplicantsForCompany";
import useAuth from "app/hooks/useAuth";
import { StatusOptions } from "app/utils/global_constants";
import { useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import FullScreenDialog from "../material-kit/dialog/FullScreenDialog";
import { useAllApplicantExtract } from "./hook/useAllApplicantExtract";
import { useCompanyApplicantExtract } from "./hook/useCompanyApplicantExtract";
import AppliedCandidateTable from "./shared/AppliedCandidateTable";
import CompanyCandidateTable from "./shared/CompanyCandidateTable";
import FilterCandidateActiveStatus from "./shared/FilterCandidateActiveStatus";

const SATableHeader = [
  "Applied On",
  "Company",
  "Candidate",
  "Job Title",
  "Current CTC",
  "Status",
  "Mail Candidate"
];

const CompanyTableHeader = [
  "Applied On",
  "Candidate",
  "Job Title",
  "Current CTC",
  "Notice Period",
  "Status"
];

function AppliedCandidate(props) {
  const { user } = useAuth();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const statusByParams = searchParams.get("status");

  const [searchTerm, setSearchTerm] = useState("");
  const [isCandidateDeleted, setIsCandidateDeleted] = useState(null);
  const [status, setStatus] = useState(
    state ? [StatusOptions.find((option) => option.value === state.status)] : []
  );

  // Control state of dialogs
  const [mailOpen, setMailOpen] = useState(false); // Controls opening and closing of mail dialog
  const [candidateMail, setCandidateMail] = useState();
  const [mailStatus, setMailStatus] = useState(false); // For snackbar

  // For pagination of data table
  const [columnFilters, setColumnFilters] = useState({
    sortOrder: "DESC",
    column: "jp_created_at",
    limit: 10,
    offset: 0,
    page: 0,
    post: "",
    status: state ? state.status : statusByParams || ""
  });

  // This get all applicants whether they are shared to company or not (used for HR role)
  const { data: appliedCandidates, isFetching: isApplicantsFetching } = useGetApplicants({
    sort: columnFilters.sortOrder,
    column: columnFilters.column,
    limit: columnFilters.limit,
    offset: columnFilters.offset,
    searchQuery: searchTerm,
    post: columnFilters.post,
    status: columnFilters.status,
    isCandidateDeleted: isCandidateDeleted?.label,
    isShared: user.role === "company" ? 1 : null
  });

  // This gets all applicants for a specific company
  const { data: applicantsToCompany, isFetching: isCompanyAppsFetching } =
    useGetApplicantsForCompany({
      id: user.id,
      status: status.map((obj) => obj.value).join(","),
      limit: columnFilters.limit,
      offset: columnFilters.offset,
      searchQuery: searchTerm,
      isCandidateDeleted: isCandidateDeleted?.label
    });

  const companyApplicants = useCompanyApplicantExtract(
    applicantsToCompany ? applicantsToCompany : { data: [], count: 0 },
    user.id,
    applicantsToCompany
  );

  // Extracts necessary columns from applicants
  const allApplicants = useAllApplicantExtract(
    appliedCandidates ? appliedCandidates : { data: [], count: 0 }
  );

  const changeSortOrder = () => {
    setColumnFilters((prevValue) => ({
      ...prevValue,
      sortOrder: prevValue.sortOrder === "ASC" ? "DESC" : "ASC"
    }));
  };

  const handleChangePage = (_, newPage) => {
    setColumnFilters((prevPage) => ({
      ...prevPage,
      page: newPage,
      offset: newPage * prevPage.limit
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setColumnFilters((prevState) => ({ ...prevState, limit: +event.target.value, page: 0 }));
  };

  const handleColumnFilterChange = (value, type, checked) => {
    if (type === "status") {
      if (checked) {
        setColumnFilters((prevValue) => ({
          ...prevValue,
          status: prevValue.status.concat(",").concat(value)
        }));
      } else {
        const newStatus = columnFilters.status.split(",").filter((status) => status !== value); // removing the status that is unchecked
        setColumnFilters((prevValue) => ({ ...prevValue, status: newStatus.join(",") })); // converting the list back to comma separated string
      }
    }

    if (type === "post") {
      if (checked) {
        setColumnFilters((prevValue) => ({
          ...prevValue,
          post: prevValue.post.concat(",").concat(value)
        }));
      } else {
        const newPost = columnFilters.post.split(",").filter((post) => post !== value); // removing the post that is unchecked
        setColumnFilters((prevValue) => ({ ...prevValue, post: newPost.join(",") }));
      }
    }
  };

  const handleRowClick = (row) => {
    window.open(`/applications/detail/${row.jp_id}`, "_blank");
  };

  const changeCandidateActiveStatus = (value) => {
    setIsCandidateDeleted(value);
  };

  const TableActions = () => {
    const actions = [
      {
        icon: (
          <Tooltip title="Send email to candidate">
            <EmailSharp style={{ color: "#01579b" }} />
          </Tooltip>
        ),
        action: (event, rowId) => {
          event.stopPropagation();
          setMailOpen(true);
          const selectedApplication = appliedCandidates.data.find(
            (application) => application.jp_id === rowId.jp_id
          );
          setCandidateMail(selectedApplication.Candidate.jp_email);
        }
      }
    ];

    if (user.role === "SA" || user.role === "company") {
      return actions;
    }

    return [];
  };

  return (
    <ListWrapper
      editOpen={false}
      alertOpen={false}
      searchlabel={"Search By Job Title / Candidate Name"}
      onChangeSearch={(searchValue) => {
        setSearchTerm(searchValue);
      }}
    >
      {isCompanyAppsFetching || (isApplicantsFetching && <MatxLoading />)}
      {mailStatus && (
        <ResponseSnackbar
          open={mailStatus}
          message={"Mail sent successfully"}
          onClose={() => setMailStatus(false)}
        />
      )}
      <FilterCandidateActiveStatus
        onChange={changeCandidateActiveStatus}
        value={isCandidateDeleted}
      />
      {mailOpen && (
        <FullScreenDialog
          title={"Send Mail"}
          open={mailOpen}
          handleClose={() => setMailOpen(false)}
        >
          <SendMailForm
            to={candidateMail}
            onSent={() => {
              setMailStatus(true);
              setMailOpen(false);
            }}
          />
        </FullScreenDialog>
      )}
      {user.role === "SA" && allApplicants !== undefined && (
        <AppliedCandidateTable
          data={allApplicants.data}
          columnSort={["Applied On"]}
          count={allApplicants.count}
          sortOrder={columnFilters.sortOrder}
          rowsPerPage={columnFilters.limit}
          currentPage={columnFilters.page}
          selectedPosts={columnFilters.post.split(",")}
          selectedStatus={columnFilters.status.split(",")}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSortOrder={changeSortOrder}
          handleRowClick={handleRowClick}
          handleChangeColumnFilter={handleColumnFilterChange}
          columnHeaders={SATableHeader}
          actions={TableActions()}
        />
      )}
      {user.role === "company" && applicantsToCompany !== undefined && (
        <CompanyCandidateTable
          data={companyApplicants.rows}
          columnSort={["Applied On"]}
          count={companyApplicants.count}
          sortOrder={columnFilters.sortOrder}
          rowsPerPage={columnFilters.limit}
          currentPage={columnFilters.page}
          selectedPosts={columnFilters.post.split(",")}
          selectedStatus={columnFilters.status.split(",")}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSortOrder={changeSortOrder}
          handleRowClick={handleRowClick}
          handleChangeColumnFilter={handleColumnFilterChange}
          columnHeaders={CompanyTableHeader}
          actions={[]}
        />
      )}
    </ListWrapper>
  );
}

export default AppliedCandidate;
