import { LoadingButton } from "@mui/lab";
import { useGetCandidateResume } from "app/hooks/candidate/useGetCandidateResume";
import { previewResume } from "app/utils/utils";
import { useState } from "react";
function DownloadResume({
  label = "View Resume",
  isCandidate,
  candidateId,
  candidateName,
  sx = {}
}) {
  const { data: dbResume, isFetched: isResumeFetched } = useGetCandidateResume({
    id: candidateId,
    fileName: "resume.pdf"
  });

  return (
    <>
      {isResumeFetched && (
        <LoadingButton variant="contained" onClick={() => previewResume(dbResume)} sx={sx}>
          {label}
        </LoadingButton>
      )}
    </>
  );
}

export default DownloadResume;
