import { getSingleJobListing } from "app/api/job_api";
import { useQuery } from "@tanstack/react-query";
const useGetJob = (selectedRowId, isUpdating, staleTime = 0) => {
  return useQuery({
    queryKey: ["get_single_job", selectedRowId, isUpdating],
    queryFn: () => getSingleJobListing(selectedRowId),
    enabled: !!selectedRowId,
    staleTime: staleTime
  });
};

export default useGetJob;
