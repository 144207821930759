import { Box, List } from "@mui/material";
import useAuth from "app/hooks/useAuth";
import useSettings from "app/hooks/useSettings";
import { formatExp } from "app/utils/global_constants";
import JobListItem from "./Job List Item/JobListItem";
import NoResultFound from "./NoResultFound";
import { Link } from "react-router-dom";
export const TextBGColor = "#ddd";

function JobList({ jobs }) {
  const { settings } = useSettings();
  const leftSidebar = settings.layout1Settings.leftSidebar;
  const { mode } = leftSidebar;
  const { isAuthenticated } = useAuth();

  let showExtraInfo = !isAuthenticated || mode === "full";

  return (
    <Box
      bgcolor={"white"}
      borderRadius={"10px"}
      sx={{ boxShadow: 3, height: { xs: "500px", md: "700px" } }}
    >
      {jobs && jobs.length === 0 && <NoResultFound />}
      {jobs && jobs.length > 0 && (
        <List sx={{ overflowY: "auto", height: "100%", bgcolor: "white" }}>
          {jobs.map(
            ({
              jp_id,
              jp_title,
              jp_city,
              jp_location,
              jp_minimum_salary,
              jp_maximum_salary,
              jp_min_exp,
              jp_max_exp,
              jp_type,
              company
            }) => {
              const { jp_company_name, jp_id: companyId } = company;
              const cities = jp_city.split("| ").map((loc) => loc.slice(loc.indexOf("-") + 1));
              const exp =
                jp_min_exp && jp_max_exp
                  ? `${formatExp(jp_min_exp)} - ${formatExp(jp_max_exp)} yrs`
                  : "Fresher";
              return (
                <Link
                  to={`/jobs/job-detail/${jp_title}-${jp_company_name}/${jp_id}`}
                  target="_blank"
                  key={jp_id}
                >
                  <JobListItem
                    id={jp_id}
                    jobTitle={jp_title}
                    companyName={jp_company_name}
                    showExtraInfo={showExtraInfo}
                    exp={exp}
                    jobType={jp_type}
                    locationType={jp_location}
                    cities={cities}
                    minSalary={jp_minimum_salary}
                    maxSalary={jp_maximum_salary}
                    companyId={companyId}
                  />
                </Link>
              );
            }
          )}
        </List>
      )}
    </Box>
  );
}

export default JobList;
