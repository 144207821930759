import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
function FormTextField({
  name,
  label,
  control,
  onInputChange = () => {},
  helperText,
  defaultValue,
  rules = {},
  ...rest
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required: helperText, ...rules }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          error={!!error}
          onChange={(e) => {
            onInputChange(e);
            field.onChange(e);
          }}
          helperText={error ? error.message : null}
          label={label}
          {...rest}
        />
      )}
    />
  );
}

export default FormTextField;
