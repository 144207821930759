import React from "react";
import { convertToBase64, getInitialsOfName } from "app/utils/utils";
import { Box, Typography } from "@mui/material";
function SquareProfile({ profile, name, containerStyles }) {
  return (
    <Box sx={containerStyles}>
      {profile && (
        <img
          src={`data:image/jpeg;base64,${convertToBase64(profile.data)}`}
          alt={"candidate profile"}
          width={"80px"}
          height={"80px"}
          style={{ backgroundColor: "#222a45", border: "1px solid #222a45", borderRadius: "5px" }}
        />
      )}
      {!profile && (
        <Box
          sx={{
            cursor: "pointer",
            bgcolor: "#222a45",
            width: "80px",
            height: "80px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "5px"
          }}
        >
          <Typography variant={"h6"}>{getInitialsOfName(name)}</Typography>
        </Box>
      )}
    </Box>
  );
}

export default SquareProfile;
