import { useQuery } from "@tanstack/react-query";
import { getApplicationOfCompany } from "app/api/application_api";
import useAuth from "../useAuth";

export const useGetApplicantsForCompany = ({
  id,
  status,
  limit,
  offset,
  searchQuery,
  isCandidateDeleted
}) => {
  const { user } = useAuth();
  return useQuery({
    queryKey: ["company_applicants", status, limit, offset, searchQuery, isCandidateDeleted],
    queryFn: () =>
      getApplicationOfCompany({
        id,
        status,
        limit,
        offset,
        searchQuery,
        isCandidateDeleted
      }),
    enabled: user.role === "company"
  });
};
