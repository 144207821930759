import ResponseSnackbar from "app/components/ResponseSnackbar";
import useAuth from "app/hooks/useAuth";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import ContentBox from "../jobs/shared/ContentBox";
import AdminProfile from "./shared/AdminProfile";
import CandidateProfile from "./shared/CandidateProfile";
import CompanyProfile from "./shared/CompanyProfile";

function Profile(props) {
  const { user } = useAuth();
  const { state } = useLocation();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [tab, setTab] = useState(state && state?.tab ? Number(state.tab) : "1");

  const handleTabChange = (event, value) => setTab(value);

  return (
    <ContentBox>
      {success && (
        <ResponseSnackbar
          message={"Profile updated successfully"}
          open={success}
          onClose={() => setSuccess(false)}
          variant={"filled"}
          severity={"success"}
          hideDuration={4000}
        />
      )}
      {error && (
        <ResponseSnackbar
          message={"Profile update failed! Please try again later."}
          open={error}
          onClose={() => setError(false)}
          variant={"filled"}
          severity={"error"}
          hideDuration={4000}
        />
      )}
      {user.role === "company" && <CompanyProfile tab={tab} changeTab={handleTabChange} />}
      {user.role === "candidate" && <CandidateProfile />}
      {user.role === "SA" && <AdminProfile />}
    </ContentBox>
  );
}

export default Profile;
