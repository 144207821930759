import useGetCountries from "app/views/jobs/hooks/useGetCountries";
import { useReducer, useState } from "react";
import { CountryStateCityReducer } from "../reducer/CandidateAddressReducer";
import { CountryCodeMap } from "../Constants";
import { State, City } from "country-state-city";
import { uniqueId } from "lodash";
import ControlledAutocomplete from "app/components/form-components/ControlledAutocomplete";
function CountryStateCity({ control, country, state, city, setValue }) {
  const [countryStateCityKey, setKey] = useState({
    country: 0,
    state: 1,
    city: 2
  });
  const [CountryStateCity, dispatch] = useReducer(CountryStateCityReducer, {
    country: country || null,
    state: state || null,
    city: city || null
  });

  function getStateCode(currentState, states) {
    const code = states.find((state) => state.label === currentState).id;
    return code;
  }

  const countries = useGetCountries();
  const states = CountryStateCity.country
    ? State.getStatesOfCountry(CountryCodeMap[CountryStateCity.country]).map((state) => ({
        id: state.isoCode,
        label: state.name
      }))
    : [];

  const cities = CountryStateCity.state
    ? City.getCitiesOfState(
        CountryCodeMap[CountryStateCity.country],
        getStateCode(CountryStateCity.state, states)
      ).map((city) => ({ id: city.name, label: city.name }))
    : [];

  const isOptionEqualToValue = (option, value) => {
    if (value && typeof value === "object") {
      return option.label === value.label;
    }
    if (value && typeof value === "string") {
      return option.label === value;
    }

    return false;
  };

  return (
    <>
      <ControlledAutocomplete
        key={countryStateCityKey.country}
        control={control}
        name={"jp_country"}
        value={CountryStateCity.country}
        label={"Country *"}
        fullWidth
        onValueSelected={(value) => {
          dispatch({ type: "SET_COUNTRY", payload: { country: value } });
          dispatch({ type: "SET_STATE", payload: { state: null } });
          dispatch({ type: "SET_CITY", payload: { city: null } });
          setKey((prev) => ({ ...prev, state: uniqueId(), city: uniqueId() }));
          setValue("jp_state", null);
        }}
        isOptionEqualToValue={isOptionEqualToValue}
        values={countries}
        helperText={"Country is required"}
      />
      <ControlledAutocomplete
        key={countryStateCityKey.state}
        control={control}
        name={"jp_state"}
        value={CountryStateCity.state}
        label={"State *"}
        fullWidth
        onValueSelected={(value) => {
          dispatch({ type: "SET_STATE", payload: { state: value } });
          dispatch({ type: "SET_CITY", payload: { city: null } });
          setKey((prev) => ({ ...prev, city: uniqueId() }));
          setValue("jp_city", null);
        }}
        isOptionEqualToValue={isOptionEqualToValue}
        values={states}
        helperText={"States is required"}
      />
      <ControlledAutocomplete
        key={countryStateCityKey.city}
        control={control}
        name={"jp_city"}
        value={CountryStateCity.city}
        label={"City *"}
        fullWidth
        onValueSelected={(value) => {
          dispatch({ type: "SET_CITY", payload: { city: value } });
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        values={cities}
        helperText={"Cities is required"}
      />
    </>
  );
}

export default CountryStateCity;
