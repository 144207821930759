import { useMemo } from "react";
import {
  Box,
  Table,
  styled,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  IconButton
} from "@mui/material";
import TableAction from "app/components/TableAction";
import StatusDropdown from "./StatusDropdown";
import SortButton from "app/views/jobs/shared/SortButton";
import Popover from "@mui/material/Popover";
import { useState } from "react";
import { FilterList } from "@mui/icons-material";
import PopoverContent from "./PopoverContent";
import useAuth from "app/hooks/useAuth";
import StatusText from "app/components/StatusText";
import { useRef } from "react";
import CandidateActiveStatusIcon from "./CandidateActiveStatusIcon";
// STYLED COMPONENT
const StyledTable = styled(Table)(() => ({
  "& thead": {
    "& tr": { "& th": { paddingLeft: 10, paddingRight: 10 } }
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } }
  },
  width: "100%",
  minWidth: "max-content",
  tableLayout: "auto",
  overflow: "scroll"
}));

// const SharedToCompany = ({
//   status,
//   applicationId,
//   companyId,
//   companyName,
//   companyEmail,
//   jobTitle,
//   candidateName
// }) => {
//   return (
//     <ShareStatusDropdown
//       status={status}
//       applicationId={applicationId}
//       companyId={companyId}
//       jobTitle={jobTitle}
//       candidateName={candidateName}
//       companyName={companyName}
//       companyEmail={companyEmail}
//     />
//   );
// };

const StatusOptions = [
  { label: "Pending", value: "pending" },
  { label: "Short-listed", value: "shortlisted" },
  { label: "Accepted (Hired)", value: "accepted" },
  { label: "Rejected", value: "rejected" }
];

function AppliedCandidateTable({
  data,
  count,
  sortOrder,
  columnHeaders,
  columnSort,
  currentPage,
  rowsPerPage,
  selectedPosts,
  selectedStatus,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSortOrder,
  handleRowClick,
  handleChangeColumnFilter,
  actions
}) {
  const { user } = useAuth();

  const [postAnchor, setPostAnchor] = useState();
  const [statusAnchor, setStatusAnchor] = useState();
  const Status = ({
    role,
    status,
    shareStatus,
    applicationId,
    candidateId,
    companyId,
    jobTitle,
    candidateName,
    companyName,
    companyMail
  }) => {
    return role === "company" || role === "SA" ? (
      <StatusDropdown
        status={status}
        shareStatus={shareStatus}
        applicationId={applicationId}
        candidateId={candidateId}
        jobTitle={jobTitle}
        companyId={companyId}
        candidateName={candidateName}
        companyName={companyName}
        companyMail={companyMail}
      />
    ) : (
      <StatusText status={status} />
    );
  };

  const TableCellWrapper = ({ isFirst = false, children }) => {
    return <TableCell align={`${isFirst ? "left" : "center"}`}>{children}</TableCell>;
  };

  const uniqueJobTitle = useMemo(() => {
    const jobPosts = [...new Set(data.map(({ jp_title }) => jp_title))]; // only unique job post titles
    return jobPosts.map((job) => ({ label: job, value: job }));
  }, [data]);

  const JobPostOptions = useRef([]);

  /* Saving all the unique job titles that were returned on first API call.
     JobPostOptions will persist those job titles.      
     If filters were applied afterwards the original list of job titles wont be lost.
  */
  if (uniqueJobTitle) {
    const currentOptions = JobPostOptions.current;
    if (currentOptions.length === 0) {
      JobPostOptions.current = uniqueJobTitle;
    }
  }

  const handleFilterClick = (event) => {
    setStatusAnchor(event.target);
  };

  return (
    <Box>
      <Box width={"100%"} sx={{ overflow: "auto" }}>
        <StyledTable>
          <TableHead>
            <TableRow>
              {columnHeaders.map((header, index) => {
                const thStyle =
                  header === "Company" || header === "Candidate" ? { width: "200px" } : {};
                return (
                  <TableCell key={`${header}_${index}`} align={"center"} style={thStyle}>
                    {header}
                    {columnSort.includes(header) && (
                      <SortButton
                        sortOrder={sortOrder}
                        identifier={header}
                        onSort={handleSortOrder}
                      />
                    )}
                    {header === "Status" && (
                      <IconButton id={header} onClick={handleFilterClick}>
                        <FilterList id={header} />
                      </IconButton>
                    )}
                    <Popover
                      open={Boolean(statusAnchor)}
                      anchorEl={statusAnchor}
                      onClose={() => setStatusAnchor(null)}
                      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    >
                      <PopoverContent
                        value={selectedStatus}
                        options={StatusOptions}
                        closePopup={() => setStatusAnchor(null)}
                        onChange={(event) => {
                          handleChangeColumnFilter(
                            event.target.value,
                            "status",
                            event.target.checked
                          );
                        }}
                        title={"Filter by Status"}
                      />
                    </Popover>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage).map((currentRow, index) => {
              console.log("🚀 ~ {data.slice ~ currentRow:", currentRow);
              return (
                <TableRow
                  key={currentRow.jp_id}
                  onClick={() => handleRowClick(currentRow)}
                  sx={{ cursor: "pointer", "&:hover": { backgroundColor: "#e8e8e8" } }}
                >
                  <TableCellWrapper>{currentRow.jp_created_at}</TableCellWrapper>
                  {/** Company Name */}
                  <TableCellWrapper>{currentRow.jp_company_name}</TableCellWrapper>
                  {/** Candidate Name */}
                  <TableCellWrapper>
                    <Box display={"flex"} gap={1} alignItems={"center"} justifyContent={"center"}>
                      {currentRow.jp_candidate_name}
                      <CandidateActiveStatusIcon isActive={!currentRow.jp_is_candidate_deleted} />
                    </Box>
                  </TableCellWrapper>
                  {/** Job Title */}
                  <TableCellWrapper>{currentRow.jp_title}</TableCellWrapper>
                  {/** Current CTC */}
                  <TableCellWrapper>{currentRow.jp_current_ctc}</TableCellWrapper>
                  {/** Notice Period*/}
                  {/* <TableCellWrapper>{currentRow.jp_notice_period}</TableCellWrapper> */}
                  {/** Status */}
                  <TableCellWrapper>
                    <Box display={"flex"} justifyContent={"center"}>
                      <StatusText status={currentRow.jp_status} />
                    </Box>
                  </TableCellWrapper>
                  {/** Shared To Company */}
                  {/* <TableCellWrapper>
                  {SharedToCompany({
                    status: currentRow.jp_is_share_to_company,
                    applicationId: currentRow.jp_id,
                    candidateName: currentRow.jp_candidate_name,
                    companyId: currentRow.jp_company_id,
                    companyName: currentRow.jp_company_name,
                    jobTitle: currentRow.jp_title,
                    companyEmail: currentRow.jp_email
                  })}
                </TableCellWrapper> */}
                  {actions &&
                    actions.map((action, index) => (
                      <TableCell align="center" key={`${action}_${index}`}>
                        <TableAction
                          key={`${currentRow.jp_id}_${index}`}
                          icon={action.icon}
                          action={(event) => {
                            action.action(event, currentRow);
                          }}
                        />
                      </TableCell>
                    ))}
                </TableRow>
              );
            })}
          </TableBody>
        </StyledTable>
      </Box>

      <TablePagination
        sx={{ px: 2, mr: 0 }}
        page={currentPage}
        component="div"
        rowsPerPage={rowsPerPage}
        count={count}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25, 50]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default AppliedCandidateTable;
