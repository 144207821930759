import React from "react";
import { Box, Typography } from "@mui/material";
function CandidateFit({ candidateSkills, requirement }) {
  let fit = "";
  let matchPercentage = 0;
  let color = "gray";

  if (candidateSkills.length > 0) {
    const matchedSkills = requirement.filter(
      (requiredSkill) =>
        candidateSkills.find(
          (candidateSkill) => candidateSkill.toLowerCase() === requiredSkill.toLowerCase()
        ) !== undefined
    ).length;
    matchPercentage = Math.round((matchedSkills / requirement.length) * 100);

    fit = `${matchPercentage}% match`;
  } else {
    fit = `0% match`;
  }

  return (
    <Box>
      <Typography color={color}>{`(${fit})`}</Typography>
    </Box>
  );
}

export default CandidateFit;
