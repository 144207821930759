import { getJobListingByCompany } from "app/api/job_api";
import { useQuery } from "@tanstack/react-query";
import useAuth from "../useAuth";
const useGetCompanyJobs = ({
  sortOrder,
  column,
  limit,
  offset,
  id,
  searchQuery,
  type,
  exp,
  location,
  city,
  ...rest
}) => {
  const { user } = useAuth();

  return useQuery({
    queryKey: [
      "company_job_listings",
      sortOrder,
      column,
      limit,
      offset,
      searchQuery,
      type,
      exp,
      location,
      city,
      rest
    ],
    queryFn: () =>
      getJobListingByCompany(
        searchQuery,
        sortOrder,
        column,
        limit,
        offset,
        id,
        type,
        exp,
        city,
        location
      ),
    enabled: user.role === "company",
    staleTime: 30000
  });
};

export default useGetCompanyJobs;
