import React from "react";
import { Grid, Chip, Avatar, Box, Typography, styled, Container } from "@mui/material";
import { H1, Paragraph } from "app/components/Typography";
import { convertToBase64, getInitialsOfName } from "app/utils/utils";
import StackChip from "../applied_candidate/shared/StackChip";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import useGetCandidate from "app/hooks/candidate/useGetCandidate";
import DownloadResume from "../applied_candidate/shared/application_detail_components/DownloadResume";
import Loading from "app/components/MatxLoading";
const QuickInfoChip = styled(Chip)({
  color: "black",
  border: "1px solid #2b324c",
  backgroundColor: "white"
});
const BorderBox = styled(Box)({
  borderRadius: "5px",
  border: "1px solid #2b324c",
  padding: "10px"
});
function CandidateDetailPage() {
  const { id } = useParams();
  let skills = [];
  let profile = "";
  let languages = [];
  let contact = {};
  let address = {};
  let education = {};
  let name = "";
  let isDeleted = false;
  let filename = "";

  const { data: candidate, isFetching: isLoading } = useGetCandidate(id);

  if (candidate) {
    name = candidate.jp_candidate_name;
    skills = candidate.Skills ? candidate.Skills.map(({ jp_id, jp_skill }) => jp_skill) : [];
    profile = convertToBase64(candidate.jp_photo ? candidate.jp_photo.data : "");
    languages = candidate.jp_language ? candidate.jp_language.split("| ") : [];
    isDeleted = candidate.jp_is_deleted;
    filename = candidate.jp_candidate_name;
    contact = {
      "Contact Number": candidate.jp_contactno,
      Email: candidate.jp_email
    };
    address = {
      Address: candidate.jp_address,
      City: candidate.jp_city,
      State: candidate.jp_state
    };
    education = {
      Qualification: candidate.jp_qualification,
      Degree: candidate.jp_degree_name
    };
  }
  return (
    <Container sx={{ backgroundColor: "#F2EFEF" }}>
      {isLoading && <Loading />}
      {!isLoading && candidate && (
        <Grid container justifyContent={"center"} alignItems={"center"} padding={2}>
          <Grid item alignSelf={"center"} justifySelf={"center"}>
            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
              <Avatar
                sx={{ width: "150px", height: "150px", bgcolor: "#222a45", fontSize: "5.5rem" }}
                src={`data:image/jpeg;base64,${profile}`}
                children={getInitialsOfName(name)}
              />
              <H1 textAlign={"center"}>{candidate.jp_candidate_name}</H1>
              <DownloadResume
                label="View Resume"
                candidateId={id}
                candidateName={filename}
                sx={{ borderRadius: "20px" }}
              />
            </Box>
            {isDeleted && <Paragraph color={"error"}>This candidate has been removed</Paragraph>}
          </Grid>
          <Grid item xs={12}>
            <Box display={"flex"} flexDirection={"column"} mt={2}>
              <Box display={"flex"} my={2} gap={2} flexWrap={"wrap"} justifyContent={"center"}>
                <QuickInfoChip
                  label={`Notice Period: ${candidate.jp_notice_period || "Not specified"}`}
                />
                <QuickInfoChip
                  label={`Current CTC: ${candidate.jp_current_ctc || "Not specified"}`}
                />
                <QuickInfoChip
                  label={`Designation: ${candidate.jp_designation || "Not specified"}`}
                />
                <QuickInfoChip label={`YOE: ${candidate.jp_yoe || "Not specified"}`} />
                <QuickInfoChip
                  label={`Current Company: ${candidate.jp_current_company || "Not specified"}`}
                />
              </Box>

              <Paragraph color={"gray"}>{candidate.jp_about_me}</Paragraph>

              <h3>Skills</h3>
              <BorderBox>
                {skills && (
                  <StackChip
                    direction={"row"}
                    spacing={1}
                    flexWrap={"wrap"}
                    data={skills}
                    rowGap={"10px"}
                  />
                )}
              </BorderBox>
              <Box gap={2}>
                {/* Education */}
                <Box>
                  <h3>Education</h3>
                  <BorderBox>
                    <Grid container>
                      {Object.entries(education).map((entry) => (
                        <Fragment key={`${entry[0]}`}>
                          <Grid item xs={6}>
                            <Paragraph>{entry[0]}: </Paragraph>
                          </Grid>
                          <Grid item xs={6} textAlign={"right"}>
                            <Paragraph fontWeight={"bold"}>{entry[1] || "Not mentioned"}</Paragraph>
                          </Grid>
                        </Fragment>
                      ))}
                    </Grid>
                  </BorderBox>
                </Box>
                {/* Language Spoken */}
                <Box flexGrow={1}>
                  <h3>Languages Spoken</h3>
                  <BorderBox>
                    {languages && (
                      <StackChip
                        direction={"row"}
                        spacing={1}
                        flexWrap={"wrap"}
                        data={languages}
                        rowGap={"10px"}
                      />
                    )}
                  </BorderBox>
                </Box>
              </Box>

              <Box display={"flex"} gap={4}>
                <Box flexGrow={1}>
                  <h3>Contact Information</h3>
                  <BorderBox>
                    <Grid container>
                      {Object.entries(contact).map((entry) => {
                        return (
                          <Fragment key={entry[0]}>
                            <Grid item xs={6}>
                              <Paragraph>{entry[0]}: </Paragraph>
                            </Grid>
                            <Grid item xs={6} textAlign={"right"}>
                              <Paragraph fontWeight={"bold"} style={{ overflowWrap: "break-word" }}>
                                {entry[1] || "Not mentioned"}
                              </Paragraph>
                            </Grid>
                          </Fragment>
                        );
                      })}
                    </Grid>
                  </BorderBox>
                </Box>
                <Box flexGrow={1}>
                  <h3>Location</h3>
                  <BorderBox>
                    <Grid container>
                      {Object.entries(address).map((entry) => (
                        <Fragment key={entry[0]}>
                          <Grid item xs={6}>
                            <Paragraph>{entry[0]}: </Paragraph>
                          </Grid>
                          <Grid item xs={6} textAlign={"right"}>
                            <Typography fontWeight={"bold"} style={{ overflowWrap: "break-word" }}>
                              {entry[1] || "Not mentioned"}
                            </Typography>
                          </Grid>
                        </Fragment>
                      ))}
                    </Grid>
                  </BorderBox>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

export default CandidateDetailPage;
