import React from "react";
import { Controller } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";
function FormAutocompleteMulti({
  key,
  name,
  label,
  values,
  defaultValue,
  control,
  sxValues,
  freeSolo = false,
  helperText,
  filterOptions,
  isOptionEqualToValue = () => {},
  onValueSelected = (value) => {},
  ...rest
}) {
  let preloadedValue = [];
  if (defaultValue) {
    if (typeof defaultValue === "string") {
      const splitVal = defaultValue.split("| ");
      preloadedValue = values.filter((defaultOption) => {
        return splitVal.some((selected) => defaultOption.label === selected);
      });
    } else {
      preloadedValue = defaultValue;
    }
  }
  return (
    <Controller
      name={name}
      freeSolo={freeSolo}
      control={control}
      defaultValue={preloadedValue || ""}
      rules={{ required: helperText }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <Autocomplete
          multiple
          key={key}
          defaultValue={preloadedValue}
          onChange={(e, value) => {
            onChange(value);
            onValueSelected(value);
          }}
          options={values || []}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={isOptionEqualToValue}
          filterSelectedOptions
          filterOptions={filterOptions}
          style={{ minWidth: "250px" }}
          renderInput={(params) => (
            <TextField
              error={!!error}
              helperText={error ? error.message : null}
              {...params}
              {...rest}
              sx={sxValues}
              label={label}
            />
          )}
        ></Autocomplete>
      )}
    />
  );
}

export default FormAutocompleteMulti;
