import { Typography } from "@mui/material";
function JobUnavailableMessage({ sx = {}, message }) {
  const msg =
    message ||
    "The position you applied for is no longer available. Please check other opportunities.";
  return (
    <Typography color={"#ff9800"} sx={sx}>
      {msg}
    </Typography>
  );
}

export default JobUnavailableMessage;
