import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { LinkOutlined } from "@mui/icons-material";
function LinkToJob({ jobTitle, jobPostId }) {
  return (
    <Tooltip title="View Job Details">
      <Link
        to={`/jobs/job-detail/${jobTitle}/${jobPostId}`}
        target="_blank"
        style={{ color: "#1976D2", width: "20px", height: "20px" }}
      >
        <LinkOutlined />
      </Link>
    </Tooltip>
  );
}

export default LinkToJob;
