import { Box, Divider, Typography } from "@mui/material";
import CustomDialog from "app/components/CustomDialog";
import useGetJob from "app/hooks/job/useGetJob";
import useGetJobs from "app/hooks/job/useGetJobs";
import useAuth from "app/hooks/useAuth";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import ApplyDialogContent from "../jobs/shared/ApplyDialogContent";
import JobDetailDialog from "../jobs/shared/JobDetailDialog";
import FilterWrapper from "./shared/Filter/FilterWrapper";
import FilterButton from "./shared/FilterButton";
import FilterDialog from "./shared/FilterDialog";
import JobList from "./shared/JobList";
import LandingPageBG from "./shared/LandingPageBG";
import "./shared/ReactPaginationStyles.css";
import SearchSection from "./shared/SearchSection";

function LandingPage(props) {
  const [pagination, setPagination] = useState({
    limit: 20,
    offset: 0,
    orderBy: "jp_created_at",
    sortOrder: "DESC",
    searchTerm: ""
  });
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showJobDetail, setShowJobDetail] = useState(false);
  const [showApplyDialog, setShowApplyDialog] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState();
  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState({
    type: [],
    exp: null,
    location: [],
    salary: [0, 30]
  });

  const handleSearchClick = (search) => setPagination((prev) => ({ ...prev, searchTerm: search }));

  const handleLocationCheck = (event) =>
    setFilter((prev) => {
      const newSelected = event.target.name;
      const checked = event.target.checked;
      const currentTypes = prev.location || [];
      if (checked) {
        return { ...prev, location: [...currentTypes, newSelected] };
      } else {
        const filteredItems = currentTypes.filter((item) => item !== newSelected);
        return {
          ...prev,
          location: filteredItems.length === 1 && filteredItems[0] === "" ? null : filteredItems
        };
      }
    });

  const handleSalaryChange = (event, newValue) => {
    setFilter((prev) => ({ ...prev, salary: newValue }));
  };

  const handleJobTypeChange = (event) => {
    setFilter((prev) => {
      const newSelected = event.target.name;
      const checked = event.target.checked;
      const currentTypes = prev.type || [];
      if (checked) {
        return { ...prev, type: [...currentTypes, newSelected] };
      } else {
        const filteredItems = currentTypes.filter((item) => item !== newSelected);
        return {
          ...prev,
          type: filteredItems.length === 1 && filteredItems[0] === "" ? null : filteredItems
        };
      }
    });
  };

  const handleViewJobDetails = (id, jobName, companyName) => {
    setSelectedRowId(id);
    // setShowJobDetail(true);
  };

  const handleOpenApplyDialog = (id) => {
    if (!user) {
      navigate("/session/login/candidate");
    } else {
      setSelectedRowId(id);
      setShowApplyDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setShowApplyDialog(false);
    setShowJobDetail(false);
    setSelectedRowId(null);
  };

  const handleChangePage = ({ selected }) => {
    setPagination((prev) => ({ ...prev, offset: selected * pagination.limit }));
  };

  const { data: job } = useGetJob(selectedRowId);

  const {
    data: jobs,
    isFetching: isAllJobsFetching,
    isFetched: isJobsFetched
  } = useGetJobs({
    sortOrder: pagination.sortOrder,
    column: "",
    limit: pagination.limit,
    offset: pagination.offset,
    searchTerm: pagination.searchTerm,
    type: filter.type,
    exp: filter.exp?.value,
    location: filter.location,
    salary: [filter.salary[0] * 100000, filter.salary[1] * 100000]
  });

  const pageCount = jobs ? Math.ceil(jobs.count / 20) : 0;

  return (
    <Box position={"relative"}>
      {showJobDetail && job && (
        <JobDetailDialog open={true} handleClose={handleCloseDialog} jobPosting={job} />
      )}
      {showApplyDialog && job && (
        <CustomDialog
          dialogTitle={`Apply for ${job.jp_title}`}
          fullWidth
          maxWidth={"md"}
          onClose={handleCloseDialog}
          open={showApplyDialog}
        >
          <ApplyDialogContent job={job} onClose={handleCloseDialog} />
        </CustomDialog>
      )}

      {/* Filter dialog for xs screens */}
      <FilterDialog open={openFilter} onClose={() => setOpenFilter(false)}>
        <FilterWrapper
          empType={filter.type}
          locType={filter.location}
          salary={filter.salary}
          exp={filter.exp}
          handleJobTypeChange={handleJobTypeChange}
          hanldeExpChange={(exp) => setFilter((prev) => ({ ...prev, exp: exp }))}
          handleSalaryChange={handleSalaryChange}
          handleLocationChange={handleLocationCheck}
        />
      </FilterDialog>

      {/* Landing Page BG */}
      <LandingPageBG />

      {/* Search Section  */}
      <Box zIndex={2} mb={4} pt={4}>
        <SearchSection handleSearchClick={handleSearchClick} />
      </Box>

      <Box
        sx={{
          display: { xs: "block", md: "flex" },
          backgroundColor: { xs: "white", md: "transparent" },
          paddingX: { xs: "0px", md: "50px" }
        }}
        gap={1}
        pb={4}
        zIndex={2}
      >
        <Box
          zIndex={2}
          flex={"30%"}
          sx={{
            display: { xs: "none", md: "block" },
            boxShadow: 3,
            p: 2,
            borderRadius: "10px",
            bgcolor: "white"
          }}
        >
          <Typography variant="h6" fontWeight={"bold"}>
            Filter
          </Typography>
          <Divider />
          <FilterWrapper
            empType={filter.type}
            locType={filter.location}
            salary={filter.salary}
            exp={filter.exp}
            handleJobTypeChange={handleJobTypeChange}
            hanldeExpChange={(exp) => setFilter((prev) => ({ ...prev, exp: exp }))}
            handleSalaryChange={handleSalaryChange}
            handleLocationChange={handleLocationCheck}
          />
        </Box>

        {/* Fitler Button only on xs screen */}
        <FilterButton onClick={() => setOpenFilter(true)} />

        {/* Job list  */}
        <Box sx={{ flex: { md: "70%" } }} height={"100%"} zIndex={2}>
          {isJobsFetched && (
            <JobList
              jobs={jobs ? jobs.data : []}
              handleViewClick={handleViewJobDetails}
              handleApply={handleOpenApplyDialog}
            />
          )}
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next >"
            previousLabel="< Prev"
            pageCount={pageCount}
            onPageChange={handleChangePage}
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageClassName={"page-item"}
            previousClassName={"previous-item"}
            nextClassName={"next-item"}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default LandingPage;
