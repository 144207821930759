import { Circle, CheckCircle, RemoveCircle } from "@mui/icons-material";
import React from "react";
const iconStyle = {
  width: "10px",
  height: "10px"
};
function CandidateActiveStatusIcon({ isActive }) {
  return <Circle color={isActive ? "success" : "error"} sx={iconStyle} />;
}

export default CandidateActiveStatusIcon;
