import React from "react";
import { Paper, List, ListItem } from "@mui/material";
import AppliedJobCard from "./AppliedJobCard";
import { H4 } from "app/components/Typography";
import NoJobs from "app/views/your_application/shared/NoJobs";
function CandidateApplication({ applications }) {
  console.log("🚀 ~ CandidateApplication ~ applications:", applications);
  return (
    <Paper>
      {!applications && <H4>Error Loading Applications</H4>}
      {applications && applications.length === 0 && <NoJobs />}
      {applications && applications.length > 0 && (
        <List
          style={{
            maxHeight: "700px",
            overflow: "auto",
            backgroundColor: "rgba(173, 173, 202, 0.3)",
            borderRadius: 10
          }}
        >
          {applications.map((item, index) => (
            <ListItem key={`${item.jp_jobpost_id}`}>
              <AppliedJobCard
                applicationId={item.jp_id}
                jobId={item.jp_jobpost_id}
                jobTitle={item.jp_title}
                companyName={item.jp_company_name}
                description={item.jp_description}
                appliedOn={item.jp_created_at}
                status={item.jp_status}
                isDeleted={item.jp_is_deleted || item.jp_is_company_deleted}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Paper>
  );
}

export default CandidateApplication;
