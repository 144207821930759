import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  MenuItem,
  Select
} from "@mui/material";
import CustomDialog from "app/components/CustomDialog";
import ResponseSnackbar from "app/components/ResponseSnackbar";
import { useUpdateStatus } from "app/hooks/application/useUpdateStatus";
import useAuth from "app/hooks/useAuth";
import useNotification from "app/hooks/useNotification";
import { useState } from "react";

function StatusDropdown({
  status,
  shareStatus,
  applicationId,
  candidateId,
  candidateName,
  companyName,
  companyId,
  companyMail,
  jobTitle
}) {
  const [selectedValue, setSelectedValue] = useState(status);
  const [newSelected, setNewSelected] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [response, setResponse] = useState({
    error: false,
    success: false,
    message: ""
  });
  const { createNotification } = useNotification();
  const { user } = useAuth();
  let bgColor = "transparent";
  let textColor = "black";

  if (selectedValue === "rejected") {
    textColor = "red";
    bgColor = "#ef9a9a";
  } else if (selectedValue === "pending") {
    textColor = "gray";
    bgColor = "lightgray";
  } else if (selectedValue === "accepted") {
    textColor = "green";
    bgColor = "lightGreen";
  } else if (selectedValue === "shortlisted") {
    textColor = "#2196f3";
    bgColor = "lightblue";
  }

  const createNotificationMessage = (candidate, jobTitle, status) => {
    return `Updated application status of candidate ${candidate} for ${jobTitle} to ${status.toUpperCase()}`;
  };

  const onUpdateStatusError = (error) => {
    console.log(error);
    setResponse((prev) => ({ ...prev, error: true, message: "Error updating status" }));
    setConfirmDialogOpen(false);
  };

  const sendNotification = () => {
    if (!candidateName || !companyName || !companyMail) {
      throw new Error("Undefined values for mail");
    }
    if (newSelected === "shortlisted" || newSelected === "accepted" || newSelected === "rejected") {
      createNotification(
        {
          jp_candidate_id: candidateId,
          jp_title: `Message from ${user.name}`,
          jp_message: `Your application for ${jobTitle} has been ${newSelected}`,
          jp_status: newSelected,
          jp_company_name: companyName,
          jp_job_title: jobTitle,
          jp_candidate_name: candidateName
        },
        "candidate"
      );
      // Company notifies HR when changing the application status of a candidate
      if (user.role === "company") {
        createNotification(
          {
            jp_title: `Message from Company ${companyName}`,
            jp_message: createNotificationMessage(candidateName, jobTitle, newSelected),
            jp_status: newSelected,
            jp_company_name: companyName,
            jp_candidate_name: candidateName,
            jp_job_title: jobTitle
          },
          "admin"
        );
      }
      // HR notifies company when changing the application status of a candidate
      if (user.role === "SA") {
        createNotification(
          {
            jp_company_id: companyId,
            jp_title: `Application Update from HR Reyan`,
            jp_message: createNotificationMessage(candidateName, jobTitle, newSelected),
            jp_status: newSelected,
            mail: {
              jp_email: companyMail,
              jp_candidate_name: candidateName,
              jp_company_name: companyName,
              jp_status: newSelected
            }
          },
          "company"
        );
      }
    }

    setResponse((prev) => ({
      ...prev,
      success: true,
      message: "Application status updated"
    }));
    setConfirmDialogOpen(false);
    setSelectedValue(newSelected);
  };

  const { mutate: updateStatus, isPending: isUpdatingStatus } = useUpdateStatus(
    applicationId,
    sendNotification,
    onUpdateStatusError
  );

  const changeStatus = (selectedStatus) => {
    // setSelectedValue(selectedStatus);
    updateStatus({ status: { jp_status: selectedStatus } });
  };

  return (
    <>
      {/* // <Tooltip
    //   title={"Share candidate first to change status"}
    //   disableHoverListener={shareStatus === 0 ? false : true}
    // > */}
      <ResponseSnackbar
        open={response.error}
        message={response.message}
        onClose={() => setResponse((prev) => ({ ...prev, error: false }))}
        severity={"error"}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      />
      <ResponseSnackbar
        open={response.success}
        message={response.message}
        onClose={() => {
          setResponse((prev) => ({ ...prev, success: false }));
          // navigate(0);
        }}
        severity={"success"}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      />

      <CustomDialog
        open={confirmDialogOpen}
        dialogTitle={"Change Application Status"}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogContent>
          <DialogContentText>
            Are you sure you want to change this application status to:{" "}
            <strong>{newSelected.toUpperCase()}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: "gray" }} onClick={() => setConfirmDialogOpen(false)}>
            Cancel
          </Button>
          <LoadingButton loading={isUpdatingStatus} onClick={() => changeStatus(newSelected)}>
            Continue
          </LoadingButton>
        </DialogActions>
      </CustomDialog>
      <Select
        value={selectedValue}
        disabled={shareStatus === 0 ? true : false}
        sx={{
          minWidth: 150,
          "& fieldset": { border: "none" },
          textAlign: "center"
        }}
        size="small"
        style={{ border: "none", backgroundColor: bgColor, color: textColor }}
        onChange={(event) => {
          setNewSelected(event.target.value);
          setConfirmDialogOpen(true);
        }}
      >
        <MenuItem value={"pending"}>Pending</MenuItem>
        <MenuItem value={"shortlisted"}>Short-Listed</MenuItem>
        <MenuItem value={"accepted"}>Accepted</MenuItem>
        <MenuItem value={"rejected"}>Rejected</MenuItem>
      </Select>
    </>
    // </Tooltip>
  );
}

export default StatusDropdown;
