export const InitialState = {
  country: undefined,
  state: undefined,
  city: undefined
};

export const AddressAction = {
  SET_COUNTRY: "SET_COUNTRY",
  SET_STATE: "SET_STATE",
  UPDATE_STATE: "UPDATE_STATE",
  SET_CITY: "SET_CITY",
  UPDATE_CITY: "UPDATE_CITY"
};

export function CountryStateCityReducer(stateValue, action) {
  const { country, state, city } = action.payload;
  switch (action.type) {
    case AddressAction.SET_COUNTRY:
      return { ...stateValue, country: country ? country.label : null };
    case AddressAction.SET_STATE:
      return { ...stateValue, state: state ? state.label : null };
    case AddressAction.SET_CITY:
      return { ...stateValue, city: city ? city.label : null };
    default:
      return stateValue;
  }
}
