import { Autocomplete, Chip, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

function Keywords({
  key,
  values,
  sxValues,
  control,
  name,
  limitTags = 10,
  label,
  defaultValue,
  rules,
  ...rest
}) {
  let preloadedValue = [];
  if (defaultValue) {
    preloadedValue = defaultValue.split("| ");
  }
  return (
    <div>
      <Controller
        name={name}
        control={control}
        defaultValue={preloadedValue || ""}
        rules={rules}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <Autocomplete
            multiple
            freeSolo
            limitTags={limitTags}
            defaultValue={preloadedValue}
            onChange={(e, value) => {
              onChange(value);
            }}
            options={values || []}
            style={{ minWidth: "250px" }}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip
                    key={key}
                    variant="outlined"
                    label={option}
                    {...tagProps}
                    sx={{
                      color: "white",
                      backgroundColor: "#2b324c",
                      border: "1px solid gray",
                      "& .MuiChip-deleteIcon": {
                        color: "white" // Color of the delete icon ("X")
                      }
                    }}
                  />
                );
              });
            }}
            renderInput={(params) => (
              <TextField
                error={!!error}
                helperText={error ? error.message : null}
                {...params}
                {...rest}
                sx={sxValues}
                label={label}
              />
            )}
          ></Autocomplete>
        )}
      />
    </div>
  );
}

export default Keywords;
