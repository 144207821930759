import { Box } from "@mui/material";
import GrayBGText from "./GrayBGText";
function JobCities({ cities }) {
  return (
    <>
      {cities && (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={1}
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          {<GrayBGText loc={`${cities[0]} `} />}
          {cities.length > 1 && <GrayBGText loc={`+${cities.length - 1}`} />}
        </Box>
      )}
    </>
  );
}

export default JobCities;
