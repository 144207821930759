import { useQuery } from "@tanstack/react-query";
import { getApplicationDetail } from "app/api/application_api";

const useGetApplicationDetail = ({ id }) => {
  return useQuery({
    queryKey: ["application_detail", id],
    queryFn: () => getApplicationDetail(id)
  });
};

export default useGetApplicationDetail;
