import { lazy } from "react";
import { Navigate } from "react-router-dom";

import { authRoles } from "./auth/authRoles";

import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";

import JobListings from "./views/jobs/JobListings";
import PostJob from "./views/jobs/PostJob";
import CandidateList from "./views/candidate/CandidateList";
import AddCandidate from "./views/candidate/AddCandidate";
import CompanyList from "./views/company/CompanyList";
import AddCompany from "./views/company/AddCompany";
import TrashList from "./views/trash/TrashList";
import ProtectedRoute from "./components/ProtectedRoute";
import AppliedCandidate from "./views/applied_candidate/AppliedCandidate";
import Profile from "./views/profile/Profile";
import YourApplications from "./views/your_application/YourApplications";
import JwtRegisterCandidate from "./views/sessions/JwtRegisterCandidate";
import JwtRegisterCompany from "./views/sessions/JwtRegisterCompany";
import JwtLoginCandidate from "./views/sessions/JwtLoginCandidate";
import JwtLoginCompany from "./views/sessions/JwtLoginCompany";
import ErrorContextProvider from "./contexts/ErrorContext";
import RedirectOnRole from "./components/RedirectOnRole";
import CompanyJobListings from "./views/jobs/CompanyJobListings";
import JobDetail from "./views/jobs/JobDetail";
import CandidateDetailPage from "./views/candidate/CandidateDetailPage";
import ApplicationDetail from "./views/applied_candidate/ApplicationDetail";

// SESSION PAGES
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const ForgotPassword = Loadable(lazy(() => import("app/views/sessions/ForgotPassword")));
// DASHBOARD PAGE
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));

const routes = [
  {
    element: <MatxLayout />,
    children: [
      // dashboard route
      {
        path: "/",
        element: <RedirectOnRole />
      },
      {
        path: "/dashboard/default",
        element: (
          <ProtectedRoute authRoles={authRoles.company.concat(authRoles.candidate)}>
            <Analytics />
          </ProtectedRoute>
        ),
        auth: authRoles.admin
      },
      {
        path: "/applications/current/:role",
        element: (
          <ProtectedRoute authRoles={authRoles.company.concat(authRoles.candidate)}>
            <AppliedCandidate />
          </ProtectedRoute>
        )
      },
      {
        path: "/applications/detail/:id",
        element: (
          <ProtectedRoute authRoles={authRoles.company.concat(authRoles.candidate)}>
            <ApplicationDetail />
          </ProtectedRoute>
        )
      },
      // jobs route
      {
        path: "/jobs/listings/:role",
        element: (
          <ProtectedRoute authRoles={authRoles.candidate}>
            <JobListings />
          </ProtectedRoute>
        )
      },
      {
        path: "/jobs/job-detail/:job/:id",
        element: <JobDetail />
      },
      {
        path: "/jobs/listings/company",
        element: (
          <ProtectedRoute authRoles={authRoles.company.concat(authRoles.candidate)}>
            <CompanyJobListings />
          </ProtectedRoute>
        )
      },
      {
        path: "/jobs/candidate/applied",
        element: (
          <ProtectedRoute authRoles={authRoles.candidate}>
            <YourApplications />
          </ProtectedRoute>
        )
      },
      {
        path: "/jobs/company/applied",
        element: (
          <ProtectedRoute authRoles={authRoles.company}>
            <AppliedCandidate />
          </ProtectedRoute>
        )
      },
      {
        path: "/jobs/postjob",
        element: (
          <ProtectedRoute authRoles={authRoles.company}>
            <PostJob />
          </ProtectedRoute>
        )
      },
      // candidate route
      {
        path: "/candidate/listings/:role",
        element: (
          <ProtectedRoute authRoles={authRoles.admin}>
            <CandidateList />
          </ProtectedRoute>
        )
      },
      {
        path: "/candidate/detail/:id",
        element: <CandidateDetailPage />
      },
      {
        path: "/candidate/addCandidate",
        element: (
          <ProtectedRoute authRoles={authRoles.admin}>
            <AddCandidate />
          </ProtectedRoute>
        ),
        auth: authRoles.admin
      },
      // company route
      {
        path: "/company/listings/:role",
        element: (
          <ProtectedRoute authRoles={authRoles.admin}>
            <CompanyList />
          </ProtectedRoute>
        )
      },
      {
        path: "/company/addCompany",
        element: (
          <ProtectedRoute authRoles={authRoles.admin}>
            <AddCompany />
          </ProtectedRoute>
        )
      },
      {
        path: "/profile",
        element: (
          <ProtectedRoute authRoles={authRoles.company.concat(authRoles.candidate)}>
            <Profile />
          </ProtectedRoute>
        )
      },
      {
        path: "/profile/:tab",
        element: (
          <ProtectedRoute authRoles={authRoles.company.concat(authRoles.candidate)}>
            <Profile />
          </ProtectedRoute>
        )
      },
      // Trash route
      {
        path: "/trash",
        element: (
          <ProtectedRoute authRoles={authRoles.admin.concat(authRoles.company)}>
            <TrashList />
          </ProtectedRoute>
        )
      }
    ]
  },

  // session pages route
  { path: "/session/404", element: <NotFound /> },
  { path: "/session/login/admin", element: <JwtLogin /> },
  { path: "/session/login/candidate", element: <JwtLoginCandidate /> },
  { path: "/session/login/company", element: <JwtLoginCompany /> },
  {
    path: "/session/signup/candidate",
    element: (
      <ErrorContextProvider>
        <JwtRegisterCandidate />
      </ErrorContextProvider>
    )
  },
  {
    path: "/session/signup/company",
    element: (
      <ErrorContextProvider>
        <JwtRegisterCompany />
      </ErrorContextProvider>
    )
  },
  { path: "/session/forgot-password", element: <ForgotPassword /> },
  { path: "/", element: <Navigate to="/" /> },
  { path: "*", element: <NotFound /> }
];

export default routes;
