import { useState, Fragment } from "react";
import { Avatar, Box, Grid, Tab, Typography } from "@mui/material";
import AddCompanyForm from "app/views/company/shared/AddCompanyForm";
import { convertToBase64 } from "app/utils/utils";
import { H1 } from "app/components/Typography";
import { EmailSharp, Language, LocationCity, Phone } from "@mui/icons-material";
import IconText from "./IconText";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import styled from "@emotion/styled";
import { useGetCompany } from "app/hooks/company/useGetCompany";
import { useUpdateCompany } from "app/hooks/company/useUpdateCompany";
import ResponseSnackbar from "app/components/ResponseSnackbar";
import ChangePassword from "./ChangePassword";
import useAuth from "app/hooks/useAuth";
import { useQueryClient } from "@tanstack/react-query";
const IconStyle = { color: "#222a45" };
const CoverImage = styled("img")({
  width: "100%",
  height: "300px"
});
function CompanyProfile({ tab, changeTab, handleSuccess, handleError }) {
  console.log("🚀 ~ CompanyProfile ~ tab:", tab);
  const { user, updateUser } = useAuth();
  const [success, setSuccess] = useState(false);
  const queryClient = useQueryClient();
  const [error, setError] = useState({
    error: false,
    msg: ""
  });

  const onUpdateSuccess = (resData) => {
    const updatedCompany = resData.company;
    updateUser({
      role: "company",
      id: updatedCompany.jp_id,
      name: updatedCompany.jp_company_name,
      email: updatedCompany.jp_email
    });
    queryClient.invalidateQueries(["company", user.id]);
    setSuccess(true);
  };

  const {
    mutate: editCompany,
    isError,
    isSuccess,
    isPending: isUpdatingCompany
  } = useUpdateCompany({
    id: user.id,
    onSuccess: onUpdateSuccess,
    onError: (error) => setError({ error: true, msg: error.message })
  });
  const { data: company } = useGetCompany({ id: user.id });

  const logo = company ? (company.jp_logo ? company.jp_logo.data : "") : "";
  const cover = company ? (company.jp_cover_photo ? company.jp_cover_photo.data : "") : "";

  return (
    <Fragment>
      <Box
        sx={{ backgroundColor: "skyblue" }}
        color={"lightblue"}
        minHeight={"200px"}
        maxHeight={"300px"}
        width={"100%"}
      >
        {cover && (
          <CoverImage
            src={`data:image/jpeg;base64,${convertToBase64(company.jp_cover_photo.data)}`}
            alt=""
            srcset=""
          />
        )}
        {!cover && (
          <Box
            sx={{ backgroundColor: "skyblue" }}
            color={"lightblue"}
            minHeight={"200px"}
            maxHeight={"300px"}
            width={"100%"}
          ></Box>
        )}
      </Box>
      <Box paddingLeft={"50px"}>
        <Avatar
          sx={{
            width: "150px",
            height: "150px",
            zIndex: "4",
            marginTop: "-100px",
            border: "8px solid white"
          }}
          src={`data:image/jpeg;base64,${convertToBase64(logo)}`}
        />
        {isSuccess && (
          <ResponseSnackbar
            message={"Profile Updated Successfully"}
            open={success}
            onClose={() => setSuccess(false)}
            variant={"filled"}
            severity={"success"}
            hideDuration={4000}
          />
        )}
        {isError && (
          <ResponseSnackbar
            message={error.msg}
            open={error}
            onClose={() => setError(false)}
            variant={"filled"}
            severity={"error"}
            hideDuration={4000}
          />
        )}
        {company && (
          <Grid container>
            <Grid item xs={12}>
              <H1 sx={{ wordWrap: "break-word" }}>{company.jp_company_name}</H1>
            </Grid>
            <Grid item justifyContent={"center"}>
              <Box>
                <TabContext value={tab}>
                  <TabList onChange={changeTab}>
                    <Tab label="About" value={"1"} />
                    <Tab label="Edit Profile" value={"2"} />
                    <Tab label="Security" value={"3"} />
                  </TabList>
                  <TabPanel value={"1"}>
                    <Typography mb={2}>{company.jp_about_company}</Typography>
                    <Box display={"flex"} flexDirection={"column"} gap={2}>
                      {company.jp_city && company.jp_state && company.jp_country && (
                        <IconText
                          icon={<LocationCity />}
                          content={`${company.jp_city.toUpperCase()}, ${
                            company.jp_state
                          }, ${company.jp_country.toUpperCase()}`}
                        />
                      )}
                      <IconText icon={<EmailSharp sx={IconStyle} />} content={company.jp_email} />
                      {company.jp_website && (
                        <IconText icon={<Language sx={IconStyle} />} content={company.jp_website} />
                      )}
                      {company.jp_contact_no && (
                        <IconText icon={<Phone sx={IconStyle} />} content={company.jp_contact_no} />
                      )}
                    </Box>
                  </TabPanel>
                  <TabPanel value={"2"}>
                    {company && (
                      <AddCompanyForm
                        mutationFn={editCompany}
                        preloadedData={company}
                        title={"Edit Profile"}
                        mutationState={isUpdatingCompany}
                      />
                    )}
                  </TabPanel>
                  <TabPanel value={"3"}>
                    <ChangePassword />
                  </TabPanel>
                </TabContext>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Fragment>
  );
}

export default CompanyProfile;
