import React from "react";
import { Box, Autocomplete, TextField } from "@mui/material";
import { Circle } from "@mui/icons-material";
const ActiveOptions = [
  { id: 1, label: "Active" },
  { id: 2, label: "Inactive" }
];
function FilterCandidateActiveStatus({ value, onChange }) {
  return (
    <Autocomplete
      options={ActiveOptions}
      size="small"
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      sx={{ width: "300px" }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={1}
            key={key}
            component="li"
            {...optionProps}
          >
            {option.label}
            <Circle
              sx={{ width: "12px", height: "12px" }}
              color={option.id === 1 ? "success" : "error"}
            />
          </Box>
        );
      }}
      renderInput={(params) => <TextField {...params} label="Filter active candidate" />}
    />
  );
}

export default FilterCandidateActiveStatus;
