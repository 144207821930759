import { convertISODateTimeToDate } from "app/utils/utils";
import { useMemo } from "react";

export const useAllApplicantExtract = (appliedCandidates, dependency) => {
  return useMemo(() => {
    const result = appliedCandidates.data.map(
      ({
        jp_id,
        jp_created_at,
        jp_candidate_id,
        jp_company_id,
        company,
        jp_status,
        Candidate,
        jp_is_share_to_company,
        jobposting,
        jp_jobpost_id
      }) => ({
        jp_id,
        jp_created_at: convertISODateTimeToDate(jp_created_at),
        jp_candidate_id,
        jp_company_id,
        jp_company_name: company.jp_company_name,
        jp_candidate_name: Candidate.jp_candidate_name,
        jp_title: jobposting.jp_title,
        jp_current_ctc: Candidate.jp_current_ctc,
        jp_email: company.jp_email,
        jp_is_candidate_deleted: Candidate.jp_is_deleted,
        jp_status,
        jp_is_share_to_company,
        jp_jobpost_id
      })
    );

    return { data: result, count: appliedCandidates.count };
  }, [appliedCandidates.count, appliedCandidates.data]);
};
